import SmoothScroll from 'smooth-scroll';

function toggleMobileMenu(open) {
  if (open) {
    document.querySelector('button.menu').classList.add('opened')
    document.querySelector('.links').classList.add('opened')
    document.querySelector('body').classList.add('locked')
  } else {
    document.querySelector('button.menu').classList.remove('opened')
    document.querySelector('.links').classList.remove('opened')
    document.querySelector('body').classList.remove('locked')
  }
}

document.querySelector('button.menu').addEventListener('click', function (e) {
  toggleMobileMenu(!document.querySelector('button.menu').classList.contains('opened'))
})

document.querySelectorAll('.main-header .links a').forEach(function (el) {
  el.addEventListener('click', function (e) {
    toggleMobileMenu(false)
  })
})

// document.querySelector('header.main-header')

function toggleHeaderClass(offset) {
  if (offset > 0) {
    document.querySelector('header.main-header').classList.add('fixed')
  } else {
    document.querySelector('header.main-header').classList.remove('fixed')
  }
}

window.addEventListener('scroll', function () { toggleHeaderClass(window.scrollY) }, { passive: true })

const _scroll = new SmoothScroll('a[href*="#"]', {
  speed: 300,
  speedAsDuration: true,
  header: '.main-header',
  offset: 40,
  topOnEmptyHash: true
});